.form-section {
  margin: 80px 0;

  @media (max-width: $screen-md) {
    margin: 60px 0 40px;
  }

  .container {
    position: relative;
  }

  h3 {
    margin-bottom: 30px;

    @media (max-width: $screen-md) {
      margin-bottom: 15px;
    }
  }

  .form-control {
    font: 500 20px/1 $main-font;
    margin-bottom: 20px;
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid $color-black;
    padding: 0;
    height: 60px;
    opacity: 0.8;

    @media (max-width: $screen-md) {
      font-size: 18px;
      height: 50px;
      margin-bottom: 15px;
    }

    @media (max-width: $screen-sm) {
      font-size: 16px;
      height: 40px;
    }

    &:focus, &:active {
      border-color: $color-main;
      box-shadow: none;
      opacity: 1;
    }

    &::placeholder {
      color: $color-black;
    }
  }

  button {
    margin-top: 10px;
  }

  .pattern {

    .parallax {
      position: absolute;

      @media (max-width: $screen-md) {
        display: none;
      }

      &:first-child {
        top: 150px;
        left: 170px;

        img {
          transform: rotate(-30deg);
        }
      }

      &:last-child {
        bottom: 160px;
        right: 220px;

        img {
          transform: rotate(90deg);
        }
      }
    }
  }

  .contacts-wrap {
    margin: 80px auto 0;

    @media (max-width: $screen-md) {
      margin: 60px 0 0;
    }
  }

  .contacts-links {
    display: flex;
    flex-wrap: wrap;

    a, p {
      font: 100 20px/1.5 $main-font;
      color: $color-black;
      text-decoration: none;
      margin: 0 20px 10px 0;
      width: 100%;

      @media (max-width: $screen-md) {
        font-size: 18px;
      }

      @media (max-width: $screen-sm) {
        font-size: 16px;
      }
    }

    a {
      font-weight: 400;
    }

    a:hover {
      color: $color-main;
    }
  }
}
