.about-section {
  margin: 80px 0;

  @media (max-width: $screen-md) {
    margin: 60px 0;
  }

  .row {
    @media (max-width: $screen-sm) {
      margin: 0;
    }
  }

  .container {
    position: relative;
  }

  .text-wrap {

    @media (max-width: $screen-sm) {
      margin-bottom: 25px;
      padding: 0;
    }

    h2 {
      margin-bottom: 30px;

      @media (max-width: $screen-md) {
        margin-bottom: 15px;
      }
    }

    p, ul {
      font: 100 20px/1.5 $main-font;

      @media (max-width: $screen-md) {
        font-size: 18px;
      }

      @media (max-width: $screen-sm) {
        font-size: 16px;
      }
    }

    p {
      margin-bottom: 5px;
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }
  }

  .image-wrap {
    position: relative;

    @media (max-width: $screen-sm) {
      padding: 0;
    }

    > img {
      position: relative;
      width: 100%;
      z-index: 1;
    }

    .text {
      position: absolute;
      margin: 0 auto;
      z-index: -1;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      transform: translateY(50%);

      p {
        font: 700 80px/1 $main-font;
        color: $color-white;
        white-space: nowrap;
        text-shadow: 1px 0 0 $color-black, -1px 0 0 $color-black,
        0 1px 0 $color-black, 0 -1px 0 $color-black,
        1px 1px $color-black, -1px -1px 0 $color-black,
        1px -1px 0 $color-black, -1px 1px 0 $color-black;
        margin: 0;

        @media (max-width: $screen-md) {
          font-size: 48px;
        }
      }
    }
  }

  .pattern {

    .parallax {
      position: absolute;
      z-index: 2;

      &:first-child {
        bottom: 30px;
        left: 160px;

        img {
          transform: rotate(90deg);
        }
      }

      &:last-child {
        top: 150px;
        right: 100px;

        img {
          transform: rotate(-30deg);
        }
      }
    }
  }
}
