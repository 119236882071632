.services-section {
  margin: 80px 0;

  @media (max-width: $screen-md) {
    margin: 40px 0 60px;
  }

  .row {
    @media (max-width: $screen-sm) {
      margin: 0;
    }
  }

  [class^="col-"] {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    z-index: 2;

    @media (max-width: $screen-sm) {
      padding: 0;
    }

    h2 {
      margin-bottom: 30px;

      @media (max-width: $screen-md) {
        margin-bottom: 15px;
      }
    }

    .items {
      display: flex;
      flex-direction: column;

      @media (max-width: $screen-sm) {
        margin-bottom: 20px;
      }

      a {
        font: 500 20px/1 $main-font;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px 0;
        text-decoration: none;
        color: $color-black;
        border-bottom: 1px solid $color-black;

        @media (max-width: $screen-md) {
          font-size: 18px;
          padding: 12px 0;
        }

        @media (max-width: $screen-xs) {
          font-size: 16px;
          padding: 10px 0;
        }

        &:hover {
          color: $color-black;
          border-color: $color-main;

          .arrow {
            opacity: 1;
            transform: none;
          }
        }

        .arrow {
          opacity: 0;
          transform: translateX(-5px);

          img {
            width: 24px;
          }
        }
      }
    }
  }

  .image-wrap {
    position: relative;

    @media (max-width: $screen-sm) {
      padding: 0;
    }

    > img {
      position: relative;
      width: 100%;
      z-index: 1;
    }

    .text {
      position: absolute;
      margin: 0 auto;
      z-index: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      transform: translateY(50%);

      p {
        font: 700 80px/1 $main-font;
        color: $color-white;
        white-space: nowrap;
        text-shadow: 1px 0 0 $color-black, -1px 0 0 $color-black,
        0 1px 0 $color-black, 0 -1px 0 $color-black,
        1px 1px $color-black, -1px -1px 0 $color-black,
        1px -1px 0 $color-black, -1px 1px 0 $color-black;
        margin: 0;

        @media (max-width: $screen-md) {
          font-size: 48px;
        }
      }
    }
  }
}
