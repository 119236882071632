.quote-section {
  margin: 80px 0;

  @media (max-width: $screen-md) {
    margin: 60px 0;
  }

  .row {
    @media (max-width: $screen-sm) {
      margin: 0;
    }
  }

  .col-12 {
    text-align: center;
    padding: 45px 30px;
    position: relative;
    background-color: #F2F2F2;

    @media (max-width: $screen-sm) {
      padding: 30px 20px;
    }

    h3 {
      font-weight: 400;
      margin-bottom: 0;
    }
  }

  .pattern {

    img {
      position: absolute;

      @media (max-width: $screen-md) {
        width: 40px;
      }

      &:first-child {
        top: -20px;
        left: 45px;

        @media (max-width: $screen-md) {
          top: -10px;
          left: 20px;
        }
      }

      &:last-child {
        bottom: -20px;
        right: 45px;

        @media (max-width: $screen-md) {
          bottom: -10px;
          right: 20px;
        }
      }
    }
  }
}
