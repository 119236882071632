footer {
  padding: 20px 0;
  border-top: 1px solid $color-black;

  @media (max-width: $screen-md) {
    padding: 30px 0;
  }

  .col-12 {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $screen-md) {
      align-items: flex-start;
      flex-direction: column;
    }

    .text {
      display: flex;
      align-items: center;

      img {
        margin-right: 30px;

        @media (max-width: $screen-md) {
          max-width: 35px;
          margin-right: 20px;
        }

        @media (max-width: $screen-sm) {
          max-width: 28px;
          margin-right: 15px;
        }
      }

      p {
        font: 400 16px/1 $main-font;
        color: $color-black;
        margin: 0;
      }
    }

    .links {

      @media (max-width: $screen-md) {
        display: flex;
        flex-direction: column;
        margin-top: 25px;
      }

      a {
        font: 400 16px/1 $main-font;
        color: $color-black;
        margin-left: 40px;
        text-transform: uppercase;
        text-decoration: none;
        position: relative;

        @media (max-width: $screen-md) {
          margin-left: 0;
          max-width: max-content;
        }

        &:not(:last-child) {
          @media (max-width: $screen-md) {
            margin-bottom: 15px;
          }
        }

        &::after {
          content: '';
          display: block;
          position: absolute;
          width: 0;
          height: 2px;
          left: 0;
          bottom: -4px;
          background-color: $color-main;
          transition: $fast-transition;
        }

        &:hover {
          color: $color-black;

          &::after {
            width: 100%;
          }
        }
      }
    }
  }
}
