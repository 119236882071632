@import "assets/scss/variables";
@import "assets/scss/header";
@import "assets/scss/hero";
@import "assets/scss/services";
@import "assets/scss/banner";
@import "assets/scss/about";
@import "assets/scss/quote";
@import "assets/scss/form";
@import "assets/scss/footer";
@import "assets/scss/notfound";

* {
  transition: $fast-transition;
  margin: 0;
}

.parallax {
  transition: none;

  img {
    @media (max-width: $screen-md) {
      width: 40px;
    }

    @media (max-width: $screen-sm) {
      width: 32px;
    }
  }
}

body {
  font-family: $main-font;
  overflow-x: hidden;
  transition: none;
}

header, main, footer {
  overflow-x: hidden;
  max-width: 100vw;
}

.container {
  @media (max-width: $screen-xs) {
    padding: 0 15px !important;
  }

  @media (min-width: $screen-xs) {
    max-width: 100% !important;
    padding: 0 15px !important;
  }

  @media (min-width: $screen-lg) {
    max-width: 1300px !important;
  }
}

p br {
  @media (max-width: $screen-xs) {
    display: none;
  }
}

a {
  cursor: pointer;
}
