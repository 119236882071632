.banner-section {
  height: 400px;
  margin: 80px 0;

  @media (max-width: $screen-md) {
    height: 300px;
    margin: 50px 0;
  }

  .container, .row, .col-12 {
    height: 100%;
  }

  .row {
    @media (max-width: $screen-md) {
      margin: 0;
    }
  }

  .col-12 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: url("../img/banner-image.png") center no-repeat;
    background-size: cover;
    position: relative;

    @media (max-width: $screen-md) {
      padding: 0;
    }

    h3 {
      text-align: center;
      color: $color-white;
      margin-bottom: 30px;

      @media (max-width: $screen-md) {
        margin-bottom: 20px;
      }
    }

    .pattern {

      .parallax {
        position: absolute;

        &:first-child {
          bottom: 130px;
          left: 135px;

          @media (max-width: $screen-md) {
            top: 30px;
            left: 50px;
          }

          img {
            transform: rotate(-90deg);
          }
        }

        &:last-child {
          bottom: 160px;
          right: 120px;

          @media (max-width: $screen-md) {
            bottom: 30px;
            right: 50px;
          }

          img {
            transform: rotate(90deg);
          }
        }
      }
    }
  }
}
