.btn-main {
  font: 500 20px/1 $main-font;
  text-decoration: none;
  padding: 12px 22px;
  color: $color-black;
  border: 3px solid $color-white;
  background-color: $color-white;
  cursor: pointer;

  @media (max-width: $screen-md) {
    font-size: 18px;
    padding: 10px 20px;
  }

  @media (max-width: $screen-sm) {
    font-size: 16px;
    padding: 10px 16px;
    border-width: 2px;
  }

  &:hover {
    color: $color-black;
    border-color: $color-black !important;

    span {
      transform: translateX(5px);
    }
  }

  &.disabled {
    pointer-events: none !important;
    opacity: 0.5;
    cursor: none;
  }

  span {
    margin-left: 15px;
    width: 24px;
    display: inline-block;

    @media (max-width: $screen-md) {
      width: 20px;
    }

    @media (max-width: $screen-sm) {
      width: 18px;
      margin-left: 10px;
    }

    img {
      width: 100%;
    }
  }

  &.btn-yellow {
    color: $color-black;
    background-color: $color-main;
    border-color: $color-main;
  }
}
