.hero-section {
  margin: 40px 0;

  @media (max-width: $screen-md) {
    margin: 87px 0 15px;
  }

  .row {
    @media (max-width: $screen-md) {
      margin: 0;
    }
  }

  .col-12 {
    position: relative;

    @media (max-width: $screen-md) {
      padding: 0;
    }
  }

  .bg {

    @media (max-width: $screen-md) {
      height: 300px;
    }

    img {
      width: 100%;

      @media (max-width: $screen-md) {
        height: 100%;
        object-fit: cover;
      }
    }
  }

  h1 {
    bottom: 50px;
    left: 50px;
    position: absolute;
    color: $color-white;
    margin: 0;

    @media (max-width: $screen-md) {
      bottom: 25px;
      left: 25px;
    }

    @media (max-width: $screen-sm) {
      bottom: 15px;
      left: 15px;
    }
  }

  .pattern {

    .parallax {
      position: absolute;

      &:first-child {
        top: 130px;
        left: 90px;

        @media (max-width: $screen-md) {
          top: 30px;
          left: 40px;
        }

        img {
          transform: rotate(-100deg);
        }
      }

      &:last-child {
        bottom: 110px;
        right: 35px;

        @media (max-width: $screen-md) {
          top: 120px;
          right: 40px;
        }

        img {
          transform: rotate(80deg);
        }
      }
    }
  }
}
