// Typography
@font-face {
  font-family: "PragmaticaExtended";
  src: local("PragmaticaExtended"),
  url("../fonts/PragmaticaExtended-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "PragmaticaExtended";
  src: local("PragmaticaExtended"),
  url("../fonts/PragmaticaExtended-Book.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "PragmaticaExtended";
  src: local("PragmaticaExtended"),
  url("../fonts/PragmaticaExtended-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "PragmaticaExtended";
  src: local("PragmaticaExtended"),
  url("../fonts/PragmaticaExtended-Bold.ttf") format("truetype");
  font-weight: 700;
}

$main-font: "PragmaticaExtended", sans-serif;

body {

  h1 {
    font: 700 54px/1.2 $main-font;

    @media (max-width: $screen-lg) {
      font-size: 48px;
    }

    @media (max-width: $screen-md) {
      font-size: 36px;
    }

    @media (max-width: $screen-sm) {
      font-size: 32px;
    }

    @media (max-width: $screen-xs) {
      font-size: 24px;
    }
  }

  h2 {
    font: 700 40px/1.2 $main-font;

    @media (max-width: $screen-lg) {
      font-size: 32px;
    }

    @media (max-width: $screen-md) {
      font-size: 24px;
    }

    @media (max-width: $screen-xs) {
      font-size: 20px;
    }
  }

  h3 {
    font: 700 32px/1.2 $main-font;

    @media (max-width: $screen-lg) {
      font-size: 24px;
    }

    @media (max-width: $screen-md) {
      font-size: 20px;
    }

    @media (max-width: $screen-xs) {
      font-size: 18px;
    }
  }
}
